(function ($) {
  $(window).on("load", function () {
    // owl-carousel
    var owl = $(".owl-carousel:not(.slider):not(.accommodations)").owlCarousel({
      items: 1.5,
      margin: 20,
      loop: true,
      autoplay: true,
      autoplayTimeout: 7000,
      smartSpeed: 500,
      animateIn: "fadeIn",
      animateOut: "fadeOut",
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: true,
      mouseDrag: false,
      responsive: {
        0: {
          items: 1.2,
        },
        576: {
          items: 1.3,
        },
        768: {
          items: 1.4,
        },
        992: {
          items: 1.5,
        },
        1200: {
          items: 1.5,
        },
      },
    });

    function updateRoundedCorners() {
      // Verwijder de afgeronde hoeken van alle items
      $(".owl-item").removeClass("no-rounded-corner");
      // Voeg afgeronde hoeken toe aan het eerste zichtbare item
      $(".owl-item.active").addClass("no-rounded-corner");
    }

    // Initialiseer de afgeronde hoeken bij het laden
    updateRoundedCorners();

    // Update de afgeronde hoeken wanneer de slide verandert
    owl.on("changed.owl.carousel", function (event) {
      updateRoundedCorners();
    });

    //
    $(".owl-carousel.accommodations").owlCarousel({
      items: 1,
      margin: 0,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // animation
      animateIn: "fadeIn",
      animateOut: "fadeOut",

      // navigation
      nav: false,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: false,

      // drag
      mouseDrag: false,
    });

    // slider
    $(".slider").owlCarousel({
      items: 1,
      margin: 25,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: false,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: false,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1.3,
        },
        576: {
          items: 2.5,
        },
        768: {
          items: 3.5,
        },
        992: {
          items: 3.5,
        },
        1200: {
          items: 4.5,
        },
      },
    });

    // form-sent
    setTimeout(() => {
      var locationSearch = "" + document.location.search;
      if (
        (-1 === locationSearch.indexOf("form[sent]") &&
          -1 === decodeURIComponent(locationSearch).indexOf("form[sent]")) ||
        -1 === document.location.hash.indexOf("#form")
      ) {
        return;
      }

      var $headerHeight = $(".header");
      var $message = $(".form-success-message");

      if (!$headerHeight.length || !$message.length) {
        return;
      }

      var currentScrollTop = $("html").scrollTop();
      var newScrollTop =
        $message.offset().top - $headerHeight.outerHeight() - 30;
      if (newScrollTop >= currentScrollTop) {
        return;
      }

      $("html, body").animate({
        scrollTop: $message.offset().top - $headerHeight.outerHeight() - 30,
      });
    }, 500);
  });

  $(document).ready(function () {
    // sticky
    require("../../vendor/w3media/framework/assets/js/V2/body-sticky-class");

    // make kasabon in smooth layout sticky
		require("../../vendor/w3media/framework/assets/js/V2/hc-sticky");
		$('.w3media-booking-receipt-widget').hcSticky({
			stickTo: '.content-section',
			top: 113,
			responsive: {
				992: {
					disable: true
				},
        1200: {
          top: 10,
				}
			}
		});

    // fancybox
    $("a[data-fancybox]").fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close",
      ],
    });

    // scroll-to-content
    $(".js-3w-scroll-to-content").on("click", function (event) {
      event.preventDefault();
      var identifier = $(this).attr("data-target");
      var $element = $(identifier);

      if ($element) {
        $("html, body").animate({ scrollTop: $element.offset().top }, 500);
      }
    });

    // faq fix
    let faqlinks = document.querySelectorAll(".faq-category a");
    faqlinks.forEach((link) => {
      if (link.id) {
        link.id = "";
        link.href = "#";
      }
    });

    // prevent scrolling on #TommyBookingSupport page
    $(window).on("hashchange", function (e) {
      if ($("#TommyBookingSupport").length > 0) {
        e.preventDefault();

        // disable scrolling
        $("body").css("overflow", "hidden");

        // set current scroll position
        $("html, body").animate({
          scrollTop: $("#TommyBookingSupport").offset().top - 120,
        });

        // enable scrolling
        setTimeout(() => {
          $("body").css("overflow", "auto");
        }, 10);
      }
    });
  });
})(jQuery);

// news slider:

document.addEventListener("DOMContentLoaded", function () {
  const ul = document.querySelector("ul.news-slider");


  if (ul) {
    const liElements = ul.querySelectorAll("li");
    liElements[0].classList.add("active");

    if (liElements.length > 1) {
      let currentIndex = 0;
  
      setInterval(() => {
        liElements.forEach((li) => li.classList.remove("active"));
        liElements[currentIndex].classList.add("active");
        currentIndex = (currentIndex + 1) % liElements.length;
      }, 5000);
    }
  }
});

// splashes
document.addEventListener("DOMContentLoaded", function () {
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.6,
  };

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("enter");
        observer.unobserve(entry.target);
      }
    });
  }, options);

  document.querySelectorAll(".home-usp-section li").forEach((li) => {
    observer.observe(li);
  });
});

// slider margin
function syncMargin() {
  const target = document.querySelector(".home-highlights-section .one");
  const containerDiv = document.querySelector(
    ".home-highlights-section .two .container-holder"
  );
  if (target && containerDiv) {
    const MarginLeft = window.getComputedStyle(target).marginLeft;
    containerDiv.style.marginLeft = MarginLeft;
  }
}

document.addEventListener("DOMContentLoaded", syncMargin);
window.addEventListener("resize", syncMargin);

// sticky mobile
document.addEventListener("scroll", function () {
  const header = document.querySelector("header");

  if ( header ) {
    if (window.scrollY > 0) {
      header.classList.add("sticky-mobile");
    } else {
      header.classList.remove("sticky-mobile");
    }
  }

});

// whatsapp menu
document.addEventListener("DOMContentLoaded", function () {
  const navLinks = document.querySelectorAll("#secondary_menu .nav-link");

  navLinks.forEach((link) => {
    if (
      link.href.includes("https://wa.me/") ||
      link.href.includes("https://api.whatsapp.com/")
    ) {
      link.classList.add("whatsapp-link");
    }
  });
});
